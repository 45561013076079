import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/react.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/redux.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/node.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/express.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/gcp.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/aws.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/fb.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/pg.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/mongo.png" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;