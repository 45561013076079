import React, { Component, Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceTwo from "../elements/service/ServiceTwo";
import { ProgressBar } from 'react-bootstrap';
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/Footer";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Programming & software solutions',
        description: `“It's not about ideas. It's about making ideas happen.”`,
        buttonText: 'Contact Us',
        buttonLink: '#contact'
    }
]


class HomeParticles extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            // <div className="active-dark">
            <Fragment>

                <Helmet pageTitle="Itekx" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/itekx-logo.png" alt="Logo Images" />
                                    <img className="logo-2" src="/assets/images/logo/itekx-logo-dark.png" alt="Logo Images" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'service', 'about', 'about-2', 'team', 'testimonial', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#home">Home</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#service">Service</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#about">About</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#about-2">Process</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#team">Team</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#testimonial">Testimonial</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles" name="home" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"
                            options={{
                                style: {
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                            enable: true,
                                            mode: "push",
                                        },
                                        onHover: {
                                            enable: true,
                                            mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 100,
                                            duration: 2,
                                            opacity: 0.8,
                                            size: 10,
                                            color: "#888",
                                        },
                                        push: {
                                            quantity: 4,
                                        },
                                        repulse: {
                                            distance: 100,
                                            duration: 0.4,
                                            color: "#888",
                                        },
                                    },
                                },
                                particles: {
                                    color: {
                                        value: "#888",
                                    },
                                    links: {
                                        color: "#888",
                                        distance: 150,
                                        enable: true,
                                        opacity: 0.5,
                                        width: 1,
                                    },
                                    collisions: {
                                        enable: true,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: 6,
                                        straight: false,

                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            value_area: 2000,
                                        },
                                        value: 80,
                                    },
                                    opacity: {
                                        value: 0.5,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        random: true,
                                        value: 5,
                                    },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="bg_image bg_image--35">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_color--1" name="service" id="service">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--8" name="about" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 style={{ color: '#ffffff' }} className="title">About Us</h2>
                                            <p className="description" style={{ color: '#7d7c7c' }} >
                                                Itekx is a startup company that provides software solution services. We are a team of proffissionals focused on the innovation of mobile and web development.
                                            </p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 style={{ color: '#ffffff' }} className="title">Mission</h3>
                                                    <p style={{ color: '#7d7c7c' }} >Our mission is turnning your ideas into a real business model.
                                                        “It's not about ideas, it's about making ideas happen.“
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 style={{ color: '#ffffff' }} className="title">Vission</h3>
                                                    <p style={{ color: '#7d7c7c' }} >We believe that a software solution should be a mixture of science and art. It will be the voice of your ideology that will represent you to others.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About 2 Area  */}
                <div className="rn-about-area ptb--120 bg_color--1" name="about-2" id="about-2">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">What do we do?</h2>
                                            <p className="description">We do plans, we build, provide and deliver the fastest and the most efficient technologies to inspire customers and scale revenue.</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Our Working Process.</h3>
                                                    <div className="rn-progress-bar progress-bar--3">
                                                        <div className="single-progress custom-color--3">
                                                            <h6 className="title">Planing</h6>
                                                            <ProgressBar now={51} />
                                                            <span className="label">51%</span>
                                                        </div>
                                                        <div className="single-progress custom-color--1">
                                                            <h6 className="title">Designing</h6>
                                                            <ProgressBar now={58} />
                                                            <span className="label">58%</span>
                                                        </div>
                                                        <div className="single-progress custom-color--4">
                                                            <h6 className="title">Development</h6>
                                                            <ProgressBar now={89} />
                                                            <span className="label">89%</span>
                                                        </div>
                                                        <div className="single-progress custom-color--2">
                                                            <h6 className="title">Managment</h6>
                                                            <ProgressBar now={43} />
                                                            <span className="label">43%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 mt_md--40 mt_sm--40">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_image bg_image--1" data-black-overlay="6" name="team" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 style={{ color: '#ffffff' }} className="title">Managing  Team</h2>
                                    <p style={{ color: '#7d7c7c' }}>Itekx has a group of talented engineers and creative programmers with a professional managing team</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}



                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--120" name="testimonial" id="testimonial">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}



                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--8 ptb--120" id="stack">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--1" name="contact" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>

        )
    }
}
export default HomeParticles;