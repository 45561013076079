import React, { Component } from "react";

let TeamContent = [
    {
        images: '01',
        title: 'Omar Suleiman',
        designation: 'Full Stack Developer',
        socialNetwork: [
        ]
    },
    {
        images: '12',
        title: 'Ahmad Mohammed',
        designation: 'Frontend Engineer',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Husein Foqara',
        designation: 'QA Engineer',
        socialNetwork: [
        ]
    },
    {
        images: '05',
        title: 'Noran Almasri',
        designation: 'UI/UX Designer',
        socialNetwork: [
        ]
    }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;