import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            ברצוני להודות לך ולהביע את הערכתי הרבה על עבודתך המקצועית והמדהימה בפיתוח ותכנות האפליקציה של סקירת מפגעים לבטיחות בעבודה שפתחת עבור החברה.
                                            אפליקציה שהקלה לי באפון משמעותי וחסכה לי זמן יקר בהכנת הדוחות על המפגעים בעבודה.
                                            "על זה אני מביע את הערכתי הרבה
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Mohammed </span> - CO, MSM Safety, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>


                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                        I am consistently impressed by their dedication to providing high-quality products and exceptional customer service. The team at ITEKX Tech is always quick to respond to any questions or concerns I have.
                                            </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Abed </span> - CEO, Eduturk Agency.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/msm.jpg" alt="Testimonial Images" />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/eduturk.png" alt="Testimonial Images" />
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;