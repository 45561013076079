import React, { Component } from "react";

const starndardService = [
    {
        image: '01',
        title: 'Website Development',
        description: 'The goal of website development is to create a user-friendly, visually appealing, and functional website that meets the needs and goals of your business.',
    },
    {
        image: '02',
        title: 'Mobile App Development',
        description: ' We develop apps with a team of professionals with expertise in various aspects of app development, such as app design, app programming, and user experience design.',
    },
    {
        image: '03',
        title: 'Cloud & Devops',
        description: 'Our DevOps team is focused on the deployment, and management of cloud-based applications and systems with the most modern tools and platforms.'
    },

]
class ServiceTwo extends Component{
    render(){
        let title = 'Services';
        // let description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            {/* <p>{description}</p> */}
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="#contact"><span className="text">Contact With Us For Custom Services</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                              {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.jpg`} alt="Corporate Images"/>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="#service">{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
